<template>
  <v-app class="grey lighten-5">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <v-card
        elevation="0"
        rounded="lg"
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="main-content-div">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="border-right image-div">
              <h2 class="text-primary-color">{{ $t("appName") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/two_factor_authentication.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2 login-main-div">
              <div class="w-100 m-auto p-2">
                <h2
                  class="text-center text-primary-color header-main-div font-weight-bold"
                >
                  {{ $t("twoFactorAuth") }}
                </h2>
                <br />

                <v-alert
                  v-if="alertMsg.status"
                  :timeout="alertMsg.timeout"
                  :color="alertMsg.color"
                  :type="alertMsg.type"
                  dismissible
                >
                  {{ alertMsg.text }}
                </v-alert>

                <v-form class="mb-4" v-model="valid" ref="form">
                  <label class="text-midgray-color">{{
                    $t("twoFactorAuthLable")
                  }}</label>
                  <div
                    class="m-auto position-relative"
                    style="max-width: 300px"
                  >
                    <v-otp-input
                      length="6"
                      v-model="forgetOtp"
                      :disabled="isOTPVerified"
                      @finish="validateOTPPassword"
                      class="font-weight-bold"
                    ></v-otp-input>

                    <h6 class="text-dark" v-if="timerCount > 0">
                      {{ $t("otp_expired") }}
                      <span class="font-weight-bold">{{ timerCount }}</span>
                      {{ $t("seconds") }}
                    </h6>
                    <v-overlay absolute :value="isOTPVerified">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>
                  <div v-if="isOtpValid">
                    <!-- <v-text-field
                      v-model="password"
                      :append-icon="
                        isPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isPwdVisible = !isPwdVisible)"
                      :type="isPwdVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      placeholder="Enter New Password"
                      :error-messages="passwordErrors"
                      clearable
                      @input="passwordcheck(password)"
                      :rules="newpasswordRules"
                    ></v-text-field> -->
                    <!-- <v-text-field
                      v-model="cpassword"
                      :append-icon="
                        isCPwdVisible
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isCPwdVisible = !isCPwdVisible)"
                      :type="isCPwdVisible ? 'text' : 'password'"
                      :label="$t('cpassword')"
                      placeholder="Re-Enter New Password"
                      clearable
                      :rules="confirmpasswordRules"
                      @input="ConfirmPassword(cpassword)"
                    ></v-text-field> -->

                    <v-text-field
                      v-model="password"
                      :append-icon="
                        isPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isPwdVisible = !isPwdVisible)"
                      :type="isPwdVisible ? 'text' : 'password'"
                      @input="passwordcheck(password)"
                      :label="$t('password')"
                      placeholder="Enter New Password"
                      clearable
                      :rules="newpasswordRules"
                      @focusin="showpass = true"
                      @focusout="showpass = false"
                      class="mb-2"
                    >
                    </v-text-field>
                    <v-card class="z-index triangle" v-if="showpass">
                      <v-row no-gutters>
                        <v-col cols="12" md="4" class="d-flex">
                          <div>
                            <v-list nav dense class="d-flex">
                              <div>
                                <v-list-item class="text-left pa-0">
                                  <v-list-item-icon class="mr-0">
                                    <v-icon
                                      :color="
                                        has_lowercase && has_uppercase
                                          ? 'green'
                                          : ''
                                      "
                                      >{{
                                        has_lowercase && has_uppercase
                                          ? "mdi-checkbox-multiple-marked-circle"
                                          : "mdi-radiobox-marked"
                                      }}</v-icon
                                    >
                                  </v-list-item-icon>
                                  <v-list-item-content class="ml-2">
                                    <v-list-item-title>
                                      Lowercase & Atleast
                                      {{ passwordRegaxObj.capital_alphabet }}
                                      Uppercase
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="text-left pa-0">
                                  <v-list-item-icon class="mr-0">
                                    <v-icon
                                      :color="has_number ? 'green' : ''"
                                      >{{
                                        has_number
                                          ? "mdi-checkbox-multiple-marked-circle"
                                          : "mdi-radiobox-marked"
                                      }}</v-icon
                                    >
                                  </v-list-item-icon>
                                  <v-list-item-content class="ml-2">
                                    <v-list-item-title>
                                      Atleast
                                      {{ passwordRegaxObj.numeric }}
                                      {{
                                        passwordRegaxObj.numeric > 1
                                          ? "Numbers"
                                          : "Number"
                                      }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>

                                <v-list-item class="text-left pa-0">
                                  <v-list-item-icon class="mr-0">
                                    <v-icon
                                      :color="has_special ? 'green' : ''"
                                      >{{
                                        has_special
                                          ? "mdi-checkbox-multiple-marked-circle"
                                          : "mdi-radiobox-marked"
                                      }}</v-icon
                                    >
                                  </v-list-item-icon>
                                  <v-list-item-content class="ml-2">
                                    <v-list-item-title>
                                      Atleast
                                      {{ passwordRegaxObj.special_character }}
                                      Special Character
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="text-left pa-0">
                                  <v-list-item-icon class="mr-0">
                                    <v-icon
                                      :color="
                                        password.length >=
                                        (passwordRegaxObj &&
                                        passwordRegaxObj.length
                                          ? passwordRegaxObj.length
                                          : 5)
                                          ? 'green'
                                          : ''
                                      "
                                      >{{
                                        password.length >=
                                        (passwordRegaxObj &&
                                        passwordRegaxObj.length
                                          ? passwordRegaxObj.length
                                          : 5)
                                          ? "mdi-checkbox-multiple-marked-circle"
                                          : "mdi-radiobox-marked"
                                      }}</v-icon
                                    >
                                  </v-list-item-icon>
                                  <v-list-item-content class="ml-2">
                                    <v-list-item-title>
                                      Atleast
                                      {{
                                        passwordRegaxObj &&
                                        passwordRegaxObj.length
                                          ? passwordRegaxObj.length
                                          : 6
                                      }}
                                      Characters</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                            </v-list>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-text-field
                      v-model="cpassword"
                      :append-icon="
                        isCPwdVisible
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isCPwdVisible = !isCPwdVisible)"
                      :type="isCPwdVisible ? 'text' : 'password'"
                      :label="$t('cpassword')"
                      placeholder="Re-Enter New Password"
                      clearable
                      :rules="confirmpasswordRules"
                      @input="ConfirmPassword(cpassword)"
                    ></v-text-field>

                    <v-btn
                      block
                      rounded
                      color="primary"
                      @click="handleSubmit"
                      class="btn-primary mt-4 text-capitalize"
                      >{{ $t("submit") }}</v-btn
                    >
                  </div>

                  <div
                    class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                  >
                    <span>{{ $t("back_to") }}</span>
                    <v-btn
                      class="text-capitalize text-primary-color p-2"
                      color="primary"
                      plain
                      @click="$router.push('/login')"
                    >
                      {{ $t("loginPage") }}?
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
        :height="100"
        :width="100"
      />
    </section>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { webbreakdown } from "../../utils/breakpoints";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
// import axios from "axios";
import { i18n as $ } from "../../locale/index";
import {
  generateRegex,
  generateSpecialCharsRegex,
  generateDigitsRegex,
  generateCapitalAlphabetsRegex,
} from "../../utils/passwordCheckers/regexCreator";
export default {
  name: "UpdatePassword",
  mixins: [alerts],
  components: {
    Loading,
  },
  data() {
    return {
      webbreakdown,
      generateRegex,
      generateSpecialCharsRegex,
      generateCapitalAlphabetsRegex,
      generateDigitsRegex,
      valid: true,
      isPwdVisible: false,
      isCPwdVisible: false,
      forgetOtp: 0,
      password: "",
      passwordRegaxObj: {},
      cpassword: "",
      submitDetailsError: false,
      errorMessage: "",
      successMessage: "",
      loader: false,
      receivedEmail: "",
      isOtpValid: false,
      user_email_from_router: this.$route.params.email,
      dismissCountDown: 20,
      dismissCountDownSuccess: 0,
      showDismissibleAlert: false,
      isOTPVerified: false,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      timerCount: 0,
      showError: false,
      showpass: false,

      password_strength: 0,
      has_number: false,
      has_lowercase: false,

      has_uppercase: false,
      has_special: false,

      newpasswordRules: [(value) => !!value || "Password is Required."],
      confirmpasswordRules: [
        (value) => !!value || "Confirm Password is Required.",
      ],
      mobileRules: [
        (v) => !!v || $.t("mobile_required"),
        (v) => /^(([0-9]*){20})$/.test(v) || $.t("mobile_required"),

        (v) => (v && v.length >= 9) || $.t("min_mobile_number"),
        (v) => (v && v.length <= 12) || $.t("max_mobile_number"),
      ],
      user_type_rules: [(v) => !!v || $.t("usertype_req")],
    };
  },
  validations() {
    return {
      forgetOtp: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
      cpassword: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
    };
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Must have atleast 6 characters.");
      !this.$v.password.validPassowrd &&
        errors.push(
          "Password must contains Uppercase, Lowercase, Number, Special characters."
        );
      return errors;
    },
    cpasswordErrors() {
      const errors = [];
      if (!this.$v.cpassword.$dirty) return errors;
      !this.$v.cpassword.required && errors.push("Password is required");
      !this.$v.cpassword.minLength &&
        errors.push("Must have atleast 6 characters.");
      !this.$v.cpassword.sameAsPassword &&
        errors.push("Passwords must be same.");
      !this.$v.cpassword.validPassowrd &&
        errors.push(
          "Password must contains Uppercase, Lowercase, Number, Special characters."
        );
      return errors;
    },
  },
  mounted() {
    this.getpasswordFormat();
    this.receivedEmail = this.$route.params.emilUser;
    this.timerCount = 300;
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && !this.isOTPVerified) {
          // Time interval to run every 1 sec.
          setTimeout(() => {
            // stop interval when time out reaches to zero.
            if (this.timerCount === 0) return;
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    removeblur() {
      this.showpass = false;
      console.log("input out focus");
    },
    showpasscheck() {
      this.showpass = true;
      console.log("input focus");
    },
    ConfirmPassword(val) {
      if (this.password != val) {
        this.confirmpasswordRules.push("Password is mismatched.");
      } else {
        this.confirmpasswordRules = [
          (value) => !!value || "Password is Required.",
        ];
      }
    },
    passwordcheck(val) {
      /* eslint-disable */
      console.log("pass :", val);
      const password = val;
      let strength = 0;
      const numeric_regax = this.generateDigitsRegex(
        this.passwordRegaxObj.numeric
      );

      this.has_number = numeric_regax.test(password);
      if ((this.has_number = numeric_regax.test(password))) {
        strength += 1;
      }
      const uppercase_regax = this.generateCapitalAlphabetsRegex(
        this.passwordRegaxObj.capital_alphabet
      );
      this.has_lowercase = /[a-z]{2}/.test(password);
      this.has_uppercase = uppercase_regax.test(password);
      if (this.has_lowercase && this.has_uppercase) {
        strength += 1;
      }
      const specialchar_regax = this.generateSpecialCharsRegex(
        this.passwordRegaxObj.special_character
      );
      this.has_special = specialchar_regax.test(password);
      if ((this.has_special = specialchar_regax.test(password))) {
        strength += 1;
      }
      if (password.length >= this.passwordRegaxObj.length) {
        strength += 1;
      }

      // If value is less than 2
      // if (strength < 2) {
      //   this.progressBarColor = "red";
      //   this.progressBarWidth = "10";
      // } else if (strength == 3) {
      //   this.progressBarColor = "orange";
      //   this.progressBarWidth = "61";
      // } else if (strength == 4) {
      //   this.progressBarColor = "green";
      //   this.progressBarWidth = "100";
      // }
      this.password_strength = strength;
      if (
        this.has_lowercase &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special &&
        password.length >= this.passwordRegaxObj.length
      ) {
        this.newpasswordRules = [(value) => !!value || "Password is Required."];
      } else {
        this.newpasswordRules.push("Password strength is weak");
      }
    },
    async getpasswordFormat() {
      try {
        // let responseData = await axios.get(
        //   `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/get-password-format`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       accept: "application/json",
        //     },
        //   }
        // );
        const responseData = await userservice.updatePassword();
        console.log("locatioln", responseData.data);
        if (responseData.data.status_code === 200) {
          console.log("locatioln==>", responseData.data.data[0]);
          this.passwordRegaxObj = responseData.data.data[0];
          console.log(this.generateRegex(responseData.data.data[0]));
        }
      } catch (e) {
        return e;
      }
    },

    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (this.password === this.cpassword) {
          this.alertMsg.status = false;
          try {
            this.loader = true;
            var changeData = {};
            if (sessionStorage.getItem("forget_login_type") == "mobile") {
              changeData = {
                user_name: sessionStorage.getItem("forget_mobile_number"),
                password: this.password,
                user_type: sessionStorage.getItem("forget_user_type"),
              };
            } else {
              changeData = {
                user_name: sessionStorage.getItem("forget_email"),
                password: this.password,
                user_type: sessionStorage.getItem("forget_user_type"),
              };
            }

            const responseData = await userservice.changePassword(changeData);
            console.log("responseData", responseData);
            if (responseData && responseData.data.success) {
              console.log("responseData in", responseData);
              this.showAlertSuccess(responseData.data.message);
              this.loader = false;

              setTimeout(() => {
                this.$router.push({
                  name: "Login",
                  params: {
                    mobile_number: sessionStorage
                      .getItem("forget_mobile_number")
                      .slice(2),
                  },
                });
              }, 1500);
            } else {
              this.loader = false;
              this.showAlertError(responseData.data.message);
            }
          } catch (error) {
            this.loader = false;
            this.showAlertError("Password cannot be same or already used!");
          }
        } else {
          this.alertMsg.status = true;
          this.alertMsg.text = "Password is mismatched";
          this.alertMsg.type = "error";
          this.alertMsg.color = "warning";
        }
      }
    },
    async validateOTPPassword() {
      var userDetail = {};
      if (sessionStorage.getItem("forget_login_type") == "mobile") {
        userDetail = {
          mobile_number: sessionStorage.getItem("forget_mobile_number"),
          otp: this.forgetOtp,
          user_email: null,
          user_type: sessionStorage.getItem("forget_user_type"),
        };
      } else {
        userDetail = {
          mobile_number: null,
          otp: this.forgetOtp,
          user_email: sessionStorage.getItem("forget_email"),
          user_type: sessionStorage.getItem("forget_user_type"),
        };
      }

      try {
        this.loader = true;
        const responseData = await userservice.validateOtpPassword(userDetail);
        console.log("responseData", responseData);
        if (responseData && responseData.data.status_code === 200) {
          this.loader = false;
          this.isOtpValid = true;
          this.timerCount = 0;
          this.showAlertSuccess(responseData.data.message);
        } else {
          this.loader = false;
          this.isOtpValid = false;

          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        this.isOtpValid = false;
        this.showAlertError("Something went wrong.Please try again.");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
.logo_img {
  max-height: 50px;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}

.z-index {
  position: absolute;
  right: 0px;
  top: 310px;
  z-index: 1 !important;
}
</style>
