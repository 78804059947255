export function generateSpecialCharsRegex(count) {
  if (count <= 0) {
    throw new Error("Count must be a positive integer.");
  }
  const specialChars = "!@#$%^&*()_\\-+=<>?,.:;{}[\\]|~";
  const regexString = `(?:[^${specialChars}]*[${specialChars}]){${count}}[^${specialChars}]*$`;
  return new RegExp(regexString);
}
export function generateDigitsRegex(count) {
  if (count <= 0) {
    throw new Error("Count must be a positive integer.");
  }

  const regexString = `\\d{${count}}`;

  return new RegExp(regexString);
}
export function generateCapitalAlphabetsRegex(count) {
  if (count <= 0) {
    throw new Error("Count must be a positive integer.");
  }

  const regexString = `[A-Z]{${count}}`;

  return new RegExp(regexString);
}

export function generateRegex(data) {
  // Define character sets
  const numericChars = "\\d".repeat(data.numeric);
  const capitalChars = "[A-Z]".repeat(data.capital_alphabet);
  const specialChars = "[!@#$%^&*]".repeat(data.special_character);

  // Combine character sets
  const combinedChars = `${numericChars}${capitalChars}${specialChars}`;

  // Generate regex pattern with specified length
  const regexPattern = `^[${combinedChars}]{${data.length}}$`;

  return regexPattern;
}
